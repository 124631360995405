import Link from "../Link/Link"

const Copy = () => {
  return (
    <div id="transform" className="section-grid mb-12 md:mb-10">
      <div className="p-6 lg:p-12 lg:col-start-7 lg:col-span-5 flex items-center order-1 lg:order-2">
        <div>
          <h3 className="font-medium text-2xl lg:text-[38px] mb-8">Good data in<br/>great data out.</h3>
          <p className="text-base lg:text-xl leading-normal mt-4 mb-8">Typesmith is at its best when you feed it existing product data; whether from your own platform, a dropship retailer, a wholesaler or manufacturer. The more detail you give it, the more accurate it is.</p>
          <p className="text-base lg:text-xl leading-normal mt-4 mb-8">Soon, we'll be able to import your existing product images and use AI to analyse each one, picking out key details and features to enhance your descriptions.</p>
          <Link url="https://calendly.com/connor-phelps-l0i/typesmith-demo?month=2024-02" text="Book a Demo" />
        </div>
      </div>
      <div className="lg:col-start-2 lg:col-span-5 px-6 lg:px-0 order-2 lg:order-1">
        <img src="../../assets/copy.svg" alt="Transform Graphic" loading="lazy" className="z-2 relative" />
      </div>
    </div>
  )
}

export default Copy